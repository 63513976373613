import React, { useRef, useState, useEffect, useCallback } from "react";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import { Container, Slider, makeStyles, withStyles } from "@material-ui/core";
import { IoIosSettings } from "react-icons/io";

import {
  IoIosFastforward,
  IoIosRewind,
  IoIosPause,
  IoIosPlay,
  IoMdVolumeHigh,
  IoMdVolumeLow,
} from "react-icons/io";
import Hls from "hls.js";
import { formatTime } from "./Format";
import { Skeleton } from "@mui/joy";
import { MdFullscreenExit } from "react-icons/md";
// import { TbRewindForward5 } from "react-icons/tb";
// import { TbRewindBackward5 } from "react-icons/tb";

const useStyles = makeStyles({
  volumeSlider: {
    width: "100%",
    color: "#9556cc",
  },
});

const PrettoSlider = withStyles({
  root: {
    height: 20,
    color: "#4723fc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  thumb: {
    height: 15,
    width: 15,
    color: "#3B18EB",
    marginTop: -3,
    marginLeft: -12,
    border: "2px solid currentColor",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 5,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

const Control = ({
  playing,
  onPlayPause,
  onRewind,
  onForward,
  onVolumeChange,
  played,
  onMuteHandle,
  onChangeCommitted,
  fullscreen,
  volume,
  onSeek,
  mute,
  onSeekMouseUp,
  currentTime,
  duration,
  controlRef,
  controlSetting,
}) => {
  const classes = useStyles();

  return (
    <div
      ref={controlRef}
      onClick={controlSetting}
      className="controler  bg-gradient-to-t from-[#1c1c1c] to-[#1c1c1c]/[0.4] absolute bottom-0 left-0 right-0 flex flex-col z-1 md:justify-between justify-around cursor-pointer"
    >
      {/* <div className="flex items-center justify-between px-6 md:py-5 py-0"></div>
      <div className="topcontainer flex items-center px-14 justify-center">
        <div
          className="icon_btn rounded-full bg-black/62 text-white  px-6 py-6"
          onClick={onPlayPause}
        >
          {playing ? (
            <IoIosPause fontSize="medium" />
          ) : (
            <IoIosPlay fontSize="medium" />
          )}
        </div>
      </div> */}
      <div className="bottom px-4">
        <div className="slider_Container flex gap-4 items-center justify-between py-0 px-10">
          <PrettoSlider
            min={0}
            max={100}
            value={played * 100}
            onChange={onSeekMouseUp}
            onChangeCommitted={onSeek}
          />
          <span className="text-[0.9rem] font-semibold ml-3 flex px-2">
            <span>{currentTime}</span>
            <span className="px-1">/</span>
            <span>{duration}</span>
          </span>
        </div>
        <div className="control-box flex items-center md:w-auto w-[100%] justify-between">
          <div className="inner_controls flex py-2 items-center md:w-[70%] w-full justify-between">
            <div className="icon_btn  md:px-6 px-2 py-0" onClick={onPlayPause}>
              {playing ? (
                <IoIosPause fontSize="medium" />
              ) : (
                <IoIosPlay fontSize="medium" />
              )}
            </div>

            <div className="icon_btn px-2 py-0" onDoubleClick={onRewind}>
              <IoIosRewind fontSize="medium" />
            </div>
            <div className="icon_btn px-2 py-0" onDoubleClick={onForward}>
              <IoIosFastforward fontSize="medium" />
            </div>
            <div className="icon_btn md:px-4 px-2 py-0" onClick={onMuteHandle}>
              {mute ? (
                <IoMdVolumeLow fontSize="medium" />
              ) : (
                <IoMdVolumeHigh fontSize="medium" />
              )}
            </div>
            <Slider
              className={`volumeSlider ${classes.volumeSlider} flex items-center w-[0%]`}
              onChange={onVolumeChange}
              value={volume * 100}
              onChangeCommitted={onChangeCommitted}
            />
            <div className="icon_btn px-2 py-0" onClick={fullscreen}>
              <MdFullscreenExit fontSize="medium" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function VodcastPlayer({ data, shimmer = false }) {
  const [videoState, setVideoState] = useState({
    playing: true,
    muted: false,
    volume: 0.5,
    played: 0,
    seeking: false,
    currentTime: "00:00",
    duration: "00:00",
  });

  const [controlVisible, setControlVisible] = useState(false);

  const { playing, muted, volume, played, seeking, currentTime, duration } =
    videoState;

  const [url, setUrl] = useState("");

  const [isReady, setIsReady] = useState(true);

  /* const url =
    "https://djur1ntoovcoi.cloudfront.net/vodcast/hls/Vertical-Song-3249a4cd66bb170aeaaac9949121a5d3/hls/master.m3u8";z */

  const playerRef = useRef(null);
  const controlRef = useRef(null);

  const [hlsInstance, setHlsInstance] = useState(null);
  const [qualities, setQualities] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState(-1);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(playerRef.current.getInternalPlayer());
      setHlsInstance(hls);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const levels = hls.levels.map((level, index) => ({
          index: index,
          height: level.height,
        }));
        setQualities(levels);

        // Attempt to play the video
        playerRef.current
          .getInternalPlayer()
          .play()
          .catch((error) => {
            console.log(
              "Autoplay was prevented. Adding a user interaction event listener."
            );
            // Add a user interaction event listener to play the video
            const playVideoOnInteraction = () => {
              playerRef.current.getInternalPlayer().play();
              // Remove the event listener once the video is playing
              document.removeEventListener("click", playVideoOnInteraction);
            };
            document.addEventListener("click", playVideoOnInteraction);
          });
      });

      return () => {
        if (hls) {
          hls.destroy();
        }
      };
    } else if (
      playerRef.current
        .getInternalPlayer()
        .canPlayType("application/vnd.apple.mpegurl")
    ) {
      playerRef.current.getInternalPlayer().src = url;
    }
  }, [url]);

  const handleQualityChange = (event) => {
    const qualityIndex = parseInt(event.target.value, 10);
    setSelectedQuality(qualityIndex);
    if (qualityIndex === -1) {
      hlsInstance.currentLevel = -1; // Auto
    } else {
      hlsInstance.currentLevel = qualityIndex;
    }
  };

  const playPauseHandler = () => {
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const rewindHandler = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5);
  };

  // const handleFullScreen = () => {

  //   setIsFullscreen(!isFullscreen);
  //   // screenfull.on("click", setControlVisible(true))
  // };

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      playerRef.current.wrapper.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
      setControlVisible(!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  // useEffect(() => {
  //   const onFullscreenChange = () => {
  //     if (screenfull.isEnabled) {
  //       setIsFullscreen(screenfull.isFullscreen);
  //       setControlVisible(true); // Show controls when entering fullscreen
  //     }
  //   };

  //   if (screenfull.isEnabled) {
  //     screenfull.on("change", onFullscreenChange);
  //   }

  //   return () => {
  //     if (screenfull.isEnabled) {
  //       screenfull.off("change", onFullscreenChange);
  //     }
  //   };
  // }, []);

  const fastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 5);
  };

  const handleSeek = (e, value) => {
    setVideoState({ ...videoState, played: parseFloat(value) / 100 });
  };

  const handleSeekMouseUp = (e, value) => {
    setVideoState({ ...videoState, seeking: false });
    playerRef.current.seekTo(value / 100);
  };

  const handleVolumeChange = (e, value) => {
    const newVolume = parseFloat(value) / 100;
    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0,
    });
  };

  const handleVolumeSeekUp = (e, value) => {
    const newVolume = parseFloat(value) / 100;
    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0,
    });
  };

  const handleMute = () => {
    setVideoState({ ...videoState, muted: !muted });
  };

  const handleMouseMove = useCallback(() => {
    setControlVisible(true);
    const timer = setTimeout(() => {
      setControlVisible(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Only hide controls if not in fullscreen mode
    if (!isFullscreen) {
      const timer = setTimeout(() => {
        setControlVisible(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isFullscreen]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current) {
        const currentTime = playerRef.current.getCurrentTime();
        const duration = playerRef.current.getDuration();
        setVideoState((prevState) => ({
          ...prevState,
          currentTime: formatTime(currentTime),
          duration: formatTime(duration),
        }));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (data) {
      const media = data.media;

      const url = media.filter((item) => item.quality === "hls");

      setUrl(url[0].file);
    }
  }, [data]);

  const useThrottle = (callback, delay) => {
    const lastCall = useRef(0);

    return (...args) => {
      const now = new Date().getTime();
      if (now - lastCall.current > delay) {
        lastCall.current = now;
        callback(...args);
      }
    };
  };

  const controlVisibilityHandler = useCallback(() => {
    if (controlRef.current) {
      controlRef.current.style.visibility = "visible";
      const timer = setTimeout(() => {
        controlRef.current.style.visibility = "hidden";
      }, 6000); // Adjust the delay as needed

      return () => clearTimeout(timer);
    }
  }, []);

  const handleOnHover = () => {
    if (!controlVisible) {
      setControlVisible(true);
      setTimeout(() => setControlVisible(false), 3000);
    }
  };

  const handleOnClick = () => {
    if (!controlVisible) {
      setControlVisible(true);
      setTimeout(() => setControlVisible(false), 5000);
    }
  };

  const throttledVisibilityHandler = useThrottle(controlVisibilityHandler, 100);

  return (
    <Skeleton
      variant="rectangular"
      loading={shimmer}
      sx={{ width: "100%", aspectRatio: 16 / 9 }}
    >
      <div className="video_Container flex flex-col justify-center items-center w-full ">
        <Container
          maxWidth="md"
          justify="center"
          // onMouseEnter={throttledVisibilityHandler}
          onMouseEnter={handleOnHover}
          onClick={handleOnClick}
        >
          <div className="player_wrapper relative">
            <ReactPlayer
              ref={playerRef}
              className="player object-cover px-0 py-0 mx-0 my-0"
              url={url}
              width="100%"
              style={{
                objectFit: "cover",
                aspectRatio: 16 / 9,
              }}
              height="100%"
              playing={playing}
              onProgress={(state) =>
                !seeking && setVideoState({ ...videoState, ...state })
              }
              muted={muted}
              volume={volume}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
            {controlVisible && (
              <Control
                controlRef={controlRef}
                playing={playing}
                onPlayPause={playPauseHandler}
                onRewind={rewindHandler}
                onForward={fastForward}
                played={played}
                onSeek={handleSeek}
                onSeekMouseUp={handleSeekMouseUp}
                onVolumeChange={handleVolumeChange}
                onChangeCommitted={handleVolumeSeekUp}
                onMuteHandle={handleMute}
                volume={volume}
                mute={muted}
                fullscreen={handleFullScreen}
                duration={duration}
                currentTime={currentTime}
              />
            )}
            {qualities.length > 0 && (
              <select
                className="absolute z-10 md:bottom-2 md:right-4 md:px-4 md:py-2 rounded-md bg-transparent text-[16px] md:text-auto"
                onChange={handleQualityChange}
                value={selectedQuality}
              >
                <option className="bg-black/[42] text-white" value="">
                  <span>
                    <IoIosSettings /> Auto
                  </span>
                </option>
                {qualities.map((quality) => (
                  <option
                    className="bg-black/[42]"
                    key={quality.index}
                    value={quality.index}
                  >
                    {quality.height}
                  </option>
                ))}
              </select>
            )}
          </div>
        </Container>
      </div>
    </Skeleton>
  );
}

export default VodcastPlayer;

// import { Box, Skeleton, IconButton, Menu, MenuItem } from "@mui/joy";
// import React, { useCallback, useEffect, useState } from "react";
// import ReactPlayer from "react-player";
// import constants from "../../../configs/constants";
// import {
//   IoIosSettings,
//   IoIosPlay,
//   IoIosSkipForward,
//   IoIosSkipBackward,
// } from "react-icons/io";

// export function Controls({ playerRef }) {
//   const rewindHandler = () => {
//     //Rewinds the video player reducing 5
//     playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5);
//   };

//   const fastFowardHandler = () => {
//     //FastFowards the video player by adding 10
//     playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
//   };

//   return (
//     <>
//       <div className="mid__container w-full h-screen gap-16 justify-between place-content-center flex relative">
//         {/* Container for forward and backward buttons */}
//         <div className="icon__btn w-10 h-10 rounded-full bg-black/[0.65] flex justify-center items-center">
//           <IoIosSkipBackward fontSize="medium" onDoubleClick={rewindHandler} />
//         </div>
//         <div className="icon__btn w-10 h-10 rounded-full bg-black/[0.65] flex justify-center items-center">
//           <IoIosSkipForward
//             fontSize="medium"
//             onDoubleClick={fastFowardHandler}
//           />
//         </div>
//       </div>
//     </>
//   );
// }

// function VodcastPlayer({ shimmer = false, controls = true, data }) {
//   const [isReady, setIsReady] = useState(false);
//   const [qualityOptions, setQualityOptions] = useState([]);
//   const [selectedQuality, setSelectedQuality] = useState("");
//   const [playedSeconds, setPlayedSeconds] = useState(0);
//   const [menuAnchorEl, setMenuAnchorEl] = useState(null);
//   const [showControls, setShowControls] = useState(true);
//   const [isPlaying, setIsPlaying] = useState(false);

//   useEffect(() => {
//     if (data) {
//       const qualityOptions = data.media.map((item) => ({
//         src: item.file,
//         type: "video/mp4",
//         size: item.quality,

//       }));
//       setQualityOptions(qualityOptions);
//       setSelectedQuality(qualityOptions[0]?.src);
//       setIsReady(true);
//     } else {
//       setIsReady(false);
//     }
//   }, [data]);

//   const handleQualityChange = (src) => {
//     const lastSec = playedSeconds;
//     setSelectedQuality(src);
//     setMenuAnchorEl(null);
//     console.log(isReady);
//     console.log(lastSec);
//     setTimeout(() => playerRef.current.seekTo(playedSeconds, "seconds"), 100);

//     /*  if (isReady && playerRef.current) {
//        console.log(playerRef.current);
//        playerRef.current.seekTo(5, 'seconds');
//      } */
//   };

//   const handleMenuOpen = (event) => {
//     setMenuAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setMenuAnchorEl(null);
//   };

//   const handlePlayPause = (isPlaying) => {
//     setShowControls(!isPlaying);
//     setIsPlaying(true); // Hide controls when playing, show when paused
//   };

//   const playerRef = React.useRef();

//   return (
//     <Box
//       sx={{
//         width: "100%",
//         display: "flex",
//         flexDirection: "column",
//         aspectRatio: 16 / 9,
//         backgroundColor: "#101010v",
//         justifyContent: "center",
//         alignItems: "center",
//         position: "relative",
//       }}
//       onMouseEnter={() => setShowControls(true)} // Show controls on mouse enter
//       onMouseLeave={() => setShowControls(false)} // Hide controls on mouse leave
//     >
//       <Skeleton
//         variant="rectangular"
//         loading={shimmer}
//         sx={{ width: "100%", aspectRatio: 16 / 9 }}
//       >
//         {isReady && (
//           <>
//             <ReactPlayer
//               ref={playerRef}
//               onProgress={(progress) => {
//                 setPlayedSeconds(progress.playedSeconds);
//               }}
//               onPlay={() => handlePlayPause(true)} // Hide controls when playing
//               onPause={() => handlePlayPause(false)} // Show controls when paused
//               onReady={() => console.log("On Ready")}
//               url={selectedQuality}
//               playing={isPlaying}
//               controls={controls}
//               width="100%"
//               height="100%"
//               config={{
//                 file: {
//                   attributes: {
//                     controlsList: "nodownload",
//                   },
//                 },
//               }}
//             />
//             <div
//               className={`w-full h-screen ${
//                 !isPlaying ? "hidden" : "flex"
//               } justify-center gap-4 absolute top-0 left-0 place-content-center`}
//             >
//               <Controls playerRef={playerRef} />
//             </div>
//             <IconButton
//               onClick={handleMenuOpen}
//               sx={{
//                 position: "absolute",
//                 top: 10,
//                 right: 10,
//               }}
//             >
//               <IoIosSettings />
//             </IconButton>
//             <Menu
//               anchorEl={menuAnchorEl}
//               open={Boolean(menuAnchorEl)}
//               onClose={handleMenuClose}
//             >
//               {qualityOptions.map((option, index) => (
//                 <MenuItem
//                   key={index}
//                   onClick={() => handleQualityChange(option.src)}
//                   sx={{
//                     backgroundColor:
//                       option.src === selectedQuality ? "blue" : "inherit",
//                     color: option.src === selectedQuality ? "white" : "inherit",
//                   }}
//                 >
//                   {option.size}
//                 </MenuItem>
//               ))}
//             </Menu>
//           </>
//         )}
//       </Skeleton>
//     </Box>
//   );
// }

// export default VodcastPlayer;

// // import { IoIosSettings } from "react-icons/io";

// // import { Box, Skeleton } from "@mui/joy";
// // import React, { useEffect, useRef, useState } from "react";
// // import ReactPlayer from "react-player";
// // import constants from "../../../configs/constants";
// // import BigPlayButton from "../BigPlayButton";
// // import VideoJS from "../VideoJS";
// // import videojs from "video.js";
// // import Plyr from "plyr-react";
// // import "plyr-react/plyr.css";

// // function VodcastPlayer({ shimmer = false, data, controls = true }) {
// //   const [playing, setPlaying] = useState(false);
// //   const [muted, setMuted] = useState(true);
// //   const [isHovered, setIsHovered] = useState(false);

// //   const [quality1080p, setQuality1080p] = useState("");
// //   const [quality720p, setQuality720p] = useState("");
// //   const [quality480p, setQuality480p] = useState("");
// //   const [quality360p, setQuality360p] = useState("");

// //   const [isReady, setIsReady] = useState(false);

// //   const [plyrProps, setPlyrProps] = useState(null);

// //   useEffect(() => {
// //     if (data) {
// //       const media = data.media;

// //       const filteredQuality1080p = media.filter(
// //         (item) => item.quality === "1080p"
// //       );
// //       const filteredQuality720p = media.filter(
// //         (item) => item.quality === "720p"
// //       );
// //       const filteredQuality480p = media.filter(
// //         (item) => item.quality === "480p"
// //       );
// //       const filteredQuality360p = media.filter(
// //         (item) => item.quality === "360p"
// //       );

// //       if (filteredQuality1080p) {
// //         setQuality1080p(filteredQuality1080p[0].file);
// //       }
// //       if (filteredQuality720p) {
// //         setQuality720p(filteredQuality720p[0].file);
// //       }
// //       if (filteredQuality480p) {
// //         setQuality480p(filteredQuality480p[0].file);
// //       }
// //       if (filteredQuality360p) {
// //         setQuality360p(filteredQuality360p[0].file);

// //         console.log(filteredQuality360p[0].file);
// //       }
// //     }
// //   }, [data]);

// //   useEffect(() => {
// //     setPlyrProps({
// //       type: "video",
// //       title: "Example Title",
// //       options: {
// //         enabled: true,
// //         controls: [
// //           "play-large",
// //           "play",
// //           "progress",
// //           "current-time",
// //           "mute",
// //           "volume",
// //           "captions",
// //           "settings",
// //           "pip",
// //           "buffered",
// //           "airplay",
// //           "fullscreen",
// //         ],
// //         //         i18n: {
// //         //   restart: 'Restart',
// //         //   rewind: 'Rewind {seektime}s',
// //         //   play: 'Play',
// //         //   pause: 'Pause',
// //         //   fastForward: 'Forward {seektime}s',
// //         //   seek: 'Seek',
// //         //   seekLabel: '{currentTime} of {duration}',
// //         //   played: 'Played',
// //         //   buffered: 'Buffered',
// //         //   currentTime: 'Current time',
// //         //   duration: 'Duration',
// //         //   volume: 'Volume',
// //         //   mute: 'Mute',
// //         //   unmute: 'Unmute',
// //         //   enableCaptions: 'Enable captions',
// //         //   disableCaptions: 'Disable captions',
// //         //   download: 'Download',
// //         //   enterFullscreen: 'Enter fullscreen',
// //         //   exitFullscreen: 'Exit fullscreen',
// //         //   frameTitle: 'Player for {title}',
// //         //   captions: 'Captions',
// //         //   settings: 'Settings',
// //         //   menuBack: 'Go back to previous menu',
// //         //   speed: 'Speed',
// //         //   normal: 'Normal',
// //         //   quality: 'Quality',
// //         //   loop: 'Loop',
// //         // },
// //         settings: ["captions", "quality", "speed", "loop"],
// //       },
// //     });

// //     if (quality1080p && quality720p && quality480p && quality360p) {
// //       setIsReady(true);
// //     } else {
// //       setIsReady(false);
// //     }
// //   }, [quality360p, quality480p, quality720p, quality1080p]);

// //   // const [videoJsOptions, setVideoJsOptions] = useState({
// //   //   width: "100%",
// //   //   height: "100%",
// //   //   autoplay: true,
// //   //   controls: true,
// //   //   responsive: true,
// //   //   fluid: true,
// //   //   sources: [
// //   //     {
// //   //       src: "https://djur1ntoovcoi.cloudfront.net/vodcast/1698983641344Homage+to+Claud+D%27Souza.mp4",
// //   //       type: data?.media?.[0].type,
// //   //       poster: data?.media?.[0].thumbnail,
// //   //     },
// //   //   ],
// //   // });

// //   // const playerRef = React.useRef(null);

// //   // const handlePlayerReady = (player) => {
// //   //   playerRef.current = player;
// //   //   player.preroll({
// //   //     src: "https://djur1ntoovcoi.cloudfront.net/vodcast/1698983641344Homage+to+Claud+D%27Souza.mp4",
// //   //   });

// //   //   // You can handle player events here, for example:
// //   //   player.on("waiting", () => {
// //   //     videojs.log("player is waiting");
// //   //   });

// //   //   player.on("dispose", () => {
// //   //     videojs.log("player will dispose");
// //   //   });
// //   // };

// //   console.log(plyrProps);

// //   return (
// //     <Box
// //       sx={{
// //         width: "100%",
// //         display: "flex",
// //         flexDirection: "column",
// //         aspectRatio: 16 / 9,
// //         backgroundColor: constants.colors.disabled,
// //         justifyContent: "center",
// //         alignItems: "center",
// //         position: "relative",
// //       }}
// //       onMouseOver={() => setIsHovered(true)}
// //       onMouseLeave={() => {
// //         setIsHovered(false);
// //       }}
// //     >
// //       <Skeleton
// //         variant="rectangular"
// //         loading={shimmer}
// //         sx={{ width: "100%", aspectRatio: 16 / 9 }}
// //       >
// //         {/* {isHovered && (
// //           <Box
// //             sx={{
// //               position: "absolute",
// //               pointerEvents: controls ? "auto" : "none",
// //             }}
// //           >
// //             <BigPlayButton
// //               // onClick={() => setPlaying((s) => !s)}
// //               playing={playing}
// //             />
// //           </Box>
// //         )} */}
// //         {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady} /> */}
// //         {/* <ReactPlayer
// //           light={
// //             <img
// //               src={data?.media?.[0].thumbnail}
// //               alt="thumbnail"
// //               style={{ width: "100%", height: "100%", objectFit: "contain" }}
// //             />
// //           }
// //           // muted={true}
// //           playIcon={
// //             <Box sx={{ position: "absolute" }}>
// //               <BigPlayButton
// //                 playing={playing}
// //               // onClick={() => setPlaying((s) => !s)}
// //               />
// //             </Box>
// //           }
// //           playsinline={true}
// //           controls={controls}
// //           config={{
// //             file: {
// //               attributes: {
// //                 controlsList: "nodownload",
// //               },
// //             },
// //           }}
// //           onReady={(e) => {
// //             // const player = e.getInternalPlayer();
// //             setPlaying(true);
// //           }}
// //           playing={playing}
// //           // playing={true}
// //           onPlay={() => setPlaying(true)}
// //           onPause={() => setPlaying(false)}
// //           url={data?.media?.[0].file}
// //           width="100%"
// //           height="100%"
// //         /> */}

// //         {isReady && (
// //           <>
// //             <Plyr
// //               source={{
// //                 title: "Title",
// //                 type: "video",

// //                 previewThumbnails: data?.media?.[0].thumbnail,
// //                 sources: [
// //                   {
// //                     src: quality360p,
// //                     type: "video/mp4",
// //                     size: 360,
// //                   },
// //                   {
// //                     src: quality480p,
// //                     type: "video/mp4",
// //                     size: 480,
// //                   },
// //                   {
// //                     src: quality720p,
// //                     type: "video/mp4",
// //                     size: 720,
// //                   },
// //                   {
// //                     src: quality1080p,
// //                     type: "video/mp4",
// //                     size: 1080,
// //                   },
// //                 ],
// //               }}
// //             />
// //           </>
// //         )}
// //       </Skeleton>
// //     </Box>
// //   );
// // }

// // export default VodcastPlayer;
