import {
  Box,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  RiChat3Line,
  RiDeleteBin3Fill,
  RiDeleteBin3Line,
  RiDraftLine,
  RiEdit2Fill,
  RiEyeLine,
  RiMenu2Line,
  RiMenu3Line,
  RiMenu5Line,
  RiMenuUnfoldLine,
  RiNotificationLine,
  RiShareForwardLine,
  RiThumbUpLine,
} from "react-icons/ri";
import { RatingStar } from "../components/RatingStar";
import { RWebShare } from "react-web-share";
import { useSelector } from "react-redux";

function VideoToolsRow({
  onReportClick,
  postRating,
  data,
  setProfileRating,
  setPostRating,
  showCommentSection,
  setShowCommentSection,
  loading,
  commentCount,
  onDeleteVodcast,
  onEditVodcast,
}) {
  const [totalPostRating, setTotalPostRating] = useState(0);

  // console.log('Print');
  /* console.log(data?.rating_data); */
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setTotalPostRating(data?.rating_data[0]?.totalrating || 0);
  }, [data]);

  const isOwn =
    user?.user_type === "professional" &&
    localStorage.getItem("user_id") === data?.created_by?._id;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: { xs: 1, sm: 1, md: 2, lg: 2 },
      }}
    >
      <Stack direction="row" sx={{ gap: 2 }}>
        <Skeleton variant="rectangular" loading={loading}>
          <Stack direction="row" sx={{ alignItems: "center", gap: 0.8 }}>
            <IconButton
              variant="solid"
              sx={{
                minWidth: 30,
                minHeight: 30,
                width: 30,
                aspectRatio: 1,
                borderRadius: 15,
                backgroundColor: "#F5F5F512",
              }}
            >
              <RatingStar
                is_rated={data?.is_rated}
                setPostRating={setPostRating}
                setProfileRating={setProfileRating}
                totalRating={totalPostRating}
                setTotalPostRating={(c) => {
                  setTotalPostRating(c);
                }}
                postId={data?._id}
                rated_count={data?.is_rated ? data?.rated_count : 0}
              />
              {/* <RiThumbUpLine size={14} /> */}
            </IconButton>
            <Typography
              level="body-sm"
              fontSize={{ xs: 10, sm: 12, md: 14, lg: 14 }}
            >
              {/* {totalPostRating} Ratings */}
              {postRating}({totalPostRating} Ratings)
            </Typography>
          </Stack>
        </Skeleton>
        <Skeleton variant="rectangular" loading={loading}>
          <Stack
            direction="row"
            sx={{ alignItems: "center", gap: 0.8 }}
            onClick={() => setShowCommentSection(!showCommentSection)}
          >
            <IconButton
              variant="solid"
              sx={{
                minWidth: 30,
                minHeight: 30,
                width: 30,
                aspectRatio: 1,
                borderRadius: 15,
                backgroundColor: "#F5F5F512",
              }}
            >
              <RiChat3Line size={14} />
            </IconButton>
            <Typography
              level="body-sm"
              fontSize={{ xs: 10, sm: 12, md: 14, lg: 14 }}
            >
              {commentCount || data?.comment_counts || 0} Comments
            </Typography>
          </Stack>
        </Skeleton>
        <Skeleton variant="rectangular" loading={loading}>
          <Stack direction="row" sx={{ alignItems: "center", gap: 0.8 }}>
            <IconButton
              variant="solid"
              sx={{
                minWidth: 30,
                minHeight: 30,
                width: 30,
                aspectRatio: 1,
                borderRadius: 15,
                backgroundColor: "#F5F5F512",
              }}
            >
              <RiEyeLine size={14} />
            </IconButton>
            <Typography
              level="body-sm"
              fontSize={{ xs: 10, sm: 12, md: 14, lg: 14 }}
            >
              {data?.views_count || 0} Views
            </Typography>
          </Stack>
        </Skeleton>
      </Stack>

      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          gap: 1.5,
          display: { xs: "none", sm: "flex" },
        }}
      >
        {/* <Skeleton variant="circular" loading={loading}>
          <IconButton
            variant="solid"
            sx={{
              minWidth: 30,
              minHeight: 30,
              width: 30,
              aspectRatio: 1,
              borderRadius: 15,
              backgroundColor: "#F5F5F512",
            }}
          >
            <RiNotificationLine size={14} />
          </IconButton>
        </Skeleton> */}
        <Skeleton variant="circular" loading={loading}>
          <RWebShare
            data={{
              text: "Musicbook",
              url: "https://musicbook.co.in/vodcast/player/" + data?._id,
              title: "Musicbook",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <Tooltip title="Share" color="neutral">
              <IconButton
                variant="solid"
                sx={{
                  minWidth: 30,
                  minHeight: 30,
                  width: 30,
                  aspectRatio: 1,
                  borderRadius: 15,
                  backgroundColor: "#F5F5F512",
                }}
              >
                <RiShareForwardLine size={14} />
              </IconButton>
            </Tooltip>
          </RWebShare>
        </Skeleton>
        {!isOwn && (
          <Skeleton variant="circular" loading={loading}>
            <Tooltip title="Report this vodcast" color="neutral">
              <IconButton
                variant="solid"
                onClick={onReportClick}
                sx={{
                  minWidth: 30,
                  minHeight: 30,
                  width: 30,
                  aspectRatio: 1,
                  borderRadius: 15,
                  backgroundColor: "#F5F5F512",
                }}
              >
                <RiDraftLine size={14} />
              </IconButton>
            </Tooltip>
          </Skeleton>
        )}
        {isOwn && (
          <Skeleton variant="circular" loading={loading}>
            <Dropdown>
              <MenuButton
                slots={{ root: IconButton }}
                sx={{
                  minWidth: 30,
                  minHeight: 30,
                  width: 30,
                  aspectRatio: 1,
                  borderRadius: 15,
                  backgroundColor: "#F5F5F512",
                }}
                slotProps={{ root: { variant: "outlined", color: "neutral" } }}
              >
                <BsThreeDotsVertical size={14} />
              </MenuButton>
              <Menu>
                <MenuItem onClick={onEditVodcast}>
                  <RiEdit2Fill size={14} /> Edit Details
                </MenuItem>
                <MenuItem color="danger" onClick={onDeleteVodcast}>
                  <RiDeleteBin3Fill size={14} /> Delete Vodcast
                </MenuItem>
              </Menu>
            </Dropdown>
          </Skeleton>
        )}
      </Stack>
    </Box>
  );
}

export default VideoToolsRow;
